// @import 'src/styles/common';
// @import 'src/styles/class';
// @import 'src/styles/global';
@font-face {
  font-family: 'Myriad Pro Regular';
  src: url('./fonts/myriad-pro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$color-white: #fff;
$color-black: #000;
$color-yellow: #ffbd3c;
$color-blue: #0f68fc;
$color-red: #ef1630;
$color-gray: #416194;
$color-dark: #0e133d;
$color-blue-light: #a3c5ff;

$dark-text: #0e133d;
$grey-text: #416194;
$grey-description: #6e92cc;
$yellow-main: #ffbd3c;
$dark-modal-15: #0c103226;
$dark-blackout-gradient: linear-gradient(
  180deg,
  rgba(12, 16, 50, 0) 0%,
  rgba(12, 16, 50, 0.6) 100%
);
$white-background: #fcfdff;
$white-surface: #ffffff;
$blue-background: #f2f5fa;
$red-m-input: #fff6f9;
$blue-main: #0f68fc;
$blue-m-input-stroke: #dce8fc;
$blue-m-stroke: #629cfc;
$blue-m-pressed: #0444ba;
$blue-m-pressed-stroke: #3172e8;
$blue-m-disabled: #a3c5ff;
$blue-m-10: #0f68fc1a;
$blue-m-5: #0f68fc0d;
$red-main: #ef1630;
$red-main-pressed: #ba0421;
$red-m-input-stroke: #f78391;
$red-m-10: #ef16301a;
$red-m-5: #ef16300d;

$fw-heavy: 900;
$fw-bold: 700;
$fw-normal: 400;
$fw-semi-bold: 600;

// desktop
$h1-desktop: 72px;
$h2-desktop: 50px;
$text-desktop: 26px;

//desktop-tablet
$h3-desktop-tablet: 29px;
$subtitle-desktop-tablet: 27px;
$button1-desktop-tablet: 24px;
$button2-desktop-tablet: 19px;
$body1-desktop-tablet: 21px;
$body2-desktop-tablet: 19px;
$description-desktop-tablet: 16px;
$copyright-desktop-tablet: 12px;

//tablet
$h1-tablet: 64px;
$h2-tablet: 44px;
$text-tablet: 22px;

//mobile
$h1-mobile: 44px;
$h2-mobile: 36px;
$h3-mobile: 26px;
$subtitle-mobile: 20px;
$text-mobile: 19px;
$button-mobile: 20px;
$body1-mobile: 16px;
$body2-mobile: 14px;
$description-mobile: 12px;
$copyright-mobile: 10px;

$maxWidth1919: 1919px;
$maxWidth1439: 1439px;
$maxWidth919: 919px;
$maxWidth767: 767px;

$paddingFor767media: 0 16px;

@mixin mediaQueries {
  @media screen and (min-width: $maxWidth1919) {
    max-width: 1104px;
  }

  @media screen and (min-width: $maxWidth1439) {
    max-width: 900px;
  }

  @media screen and (max-width: $maxWidth919) {
    max-width: 704px;
  }

  @media screen and (max-width: $maxWidth767) {
    max-width: 100%;
    padding: $paddingFor767media;
  }
}

@mixin flex($justifyContent, $alignItems, $direction) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $direction;
  flex-wrap: wrap;
}

@mixin font($fontSize, $textAlign, $fontFamily) {
  font-family: $fontFamily;
  font-size: $fontSize;
  text-align: $textAlign;
}

$mobile-width: 374px;
$tablet-width: 767px;
$ipad-width: 1023px;
$laptop-width: 1439px;
$desktop-width: 1919px;

@mixin mobile {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$ipad-width}) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: #{$ipad-width}) and (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$ipad-width}) {
    @content;
  }
}

@mixin mobile-ipad {
  @media (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin mobile-laptop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-ipad {
  @media (min-width: #{$tablet-width}) and (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin tablet-laptop {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin ipad-desktop {
  @media (min-width: #{$ipad-width}) {
    @content;
  }
}

@mixin ipad-laptop {
  @media (min-width: #{$ipad-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin laptop-desktop {
  @media (min-width: #{$laptop-width}) {
    @content;
  }
}

.container {
  max-width: 1590px;
  width: 100%;

  @include laptop {
    max-width: 1110px;
  }

  @include tablet-ipad {
    max-width: 100%;
    padding: 0 32px;
  }

  @include mobile {
    padding: 0 16px;
  }
}

.thinContainer {
  max-width: 1185px;
  width: 100%;

  p {
    line-height: 140%;
  }

  @include laptop {
    max-width: 1015px;
  }
}

.postDetailsPage {
  font-family: 'Myriad Pro Regular';
  // position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #416194;
}

.backButton {
  margin-bottom: 36px;
  @include tablet {
    margin-bottom: 32px;
  }
  @include mobile {
    margin-bottom: 24px;
  }
}

.quotation {
  display: flex;
  gap: 16px;
  padding: 32px;
  background-color: $blue_background;
  border-radius: 20px;
  margin-top: 32px;
  font-size: $body2-desktop-tablet;
  @include mobile {
    padding: 24px;
    font-size: $body2-mobile;
  }
  .icon {
    transform: translateY(-8px);
  }
}

.text {
  width: 100%;
  margin-top: 56px;
  overflow-wrap: break-word;
  font-size: $body1-desktop-tablet;
  @include mobile {
    font-size: $body1-mobile;
  }
  @include mobile-tablet {
    margin-top: 32px;
  }
}

.mainPhotoWrapper {
  width: 100%;
  height: fit-content;
  margin-top: 80px;
  @include tablet {
    margin-top: 32px;
  }
  @include mobile {
    margin-top: 24px;
  }

  .mainPhoto {
    object-fit: cover;
    width: 100%;
    max-height: 640px;
    border-radius: 20px;
  }
}

.articles {
  margin-top: 240px;
  margin-bottom: 240px;
  @include laptop {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  @include tablet {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  @include mobile {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    gap: 40px 56px;
    flex-wrap: wrap;
    @include laptop {
      gap: 30px;
    }
    @include tablet {
      gap: 24px;
    }
    @include mobile-tablet {
      margin-top: 32px;
    }
  }
}

.date {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 36px;
  font-size: $description-desktop-tablet;
  @include mobile {
    font-size: $description-mobile;
  }
  @include tablet {
    margin-top: 32px;
  }
  @include mobile {
    margin-top: 24px;
  }

  .tag {
    margin-right: 8px;
    color: $red_main;
    padding: 8px 20px;
    border-radius: 10px;
    background-color: $red_m_10;
    font-size: $body2-desktop-tablet;
    font-weight: $fw-bold;
    @include mobile {
      font-size: $body2-mobile;
    }
  }

  .icon {
    color: $red_main;
    font-size: 20px;
    transform: translateY(3px);
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 56px;
  @include mobile-tablet {
    margin-top: 32px;
    align-items: flex-start;
  }
  @include mobile {
    flex-direction: column;
    gap: 24px;
  }

  .tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    @include laptop-desktop {
      width: 412px;
    }
    .tag {
      color: $blue_main;
      font-weight: $fw-bold;
      padding: 8px 20px;
      background-color: $blue_m_10;
      border-radius: 10px;
    }
  }
  .share {
    color: $blue_main;
    font-size: $text-desktop;
    display: flex;
    gap: 16px;
    align-items: center;
    @include tablet {
      font-size: $text-tablet;
    }
    @include mobile {
      font-size: $text-mobile;
    }
    &:hover {
      cursor: pointer;
    }
    .icon {
      width: 64px;
      height: 64px;
      background-color: $blue_m_10;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 20px;
      transition: all ease-in-out 0.3s;
      i {
        transform: translateY(2px);
      }

      @include mobile-tablet {
        width: 48px;
        height: 48px;
      }

      &:hover {
        background-color: $blue_main;
        color: $white_surface;
      }
    }
  }
}

.modal {
  max-width: 645px;
  padding: 48px 32px;
  background-color: $white_background;
  border-radius: 20px;
  box-shadow: 0px 10px 16px rgba(20, 29, 107, 0.12), 0px 6px 6px rgba(20, 29, 107, 0.13);
  .text {
    font-size: $body1-desktop-tablet;
    @include mobile {
      font-size: $body1-mobile;
    }
  }

  .socials {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    font-size: 24px;
    list-style: none;
    padding: 0;
    gap: 16px;
    @include mobile-tablet {
      flex-wrap: wrap;
    }

    li {
      color: $blue_main;
      background-color: $blue_m_10;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in-out 0.3s;
      @include mobile {
        width: 48px;
        height: 48px;
      }

      &:hover {
        background-color: $blue_main;
        color: $white_background;
        cursor: pointer;
      }

      i {
        transform: translateY(3px);
      }
    }
  }

  .form {
    display: flex;
    height: 59px;
    margin-top: 56px;
    justify-content: space-between;
    gap: 10px;

    .button {
      width: 148px;
    }

    .input {
      border-radius: 10px;
      width: 100%;
      outline: none;
      border: 2px solid $blue_m_input_stroke;
      padding: 16px 24px;
      font-size: $body2-desktop-tablet;
      @include mobile {
        font-size: $body2-mobile;
      }
    }
  }
}

.ellipse {
  position: absolute;
  height: 900px;
  width: 900px;
  top: 400px;
  left: calc(100vw - 400px);
  transform: rotate(-15deg);
}


.RTE {
  [contenteditable='true'] {
    min-height: calc(100vh - 300px);
    max-width: 800px;
  }
}
